<template>
  <div>
    <ejs-grid ref="grid" :dataSource="items">
      <e-columns>
        <e-column
          field="id"
          headerText=""
          isPrimaryKey="{true}"
          :template="imageTemplate"
          width="90"
          textAlign="Center"
        ></e-column>
        <e-column :template="nameTemplate" headerText="Nome"></e-column>
        <e-column
          :template="priceTemplate"
          headerText="Prezzo"
          width="140"
        ></e-column>
        <e-column
          :template="quantityTemplate"
          headerText="Quantità"
          width="240"
        ></e-column>
        <e-column
          :template="totalTemplate"
          textAlign="right"
          headerText="Totale"
          width="240"
        ></e-column>
      </e-columns>
    </ejs-grid>
    <hr />
    <div class="text-right mb-3" v-if="cart">
      <div>
        <div class="ui-type-caption text-uppercase">TOTALE SPESA</div>
        <div class="ui-type-display-sm text-uppercase">
          {{ cartTotal(cart) }}
        </div>
      </div>
    </div>
    <div class="text-right" v-if="cart">
      <form :action="checkoutActionUrl" method="post">
        <input type="hidden" name="token" :value="token" />
        <input type="hidden" name="redirect_url" :value="redirect_url" />
        <input type="hidden" name="carts_url" :value="carts_url" />
        <input type="hidden" name="market_url" :value="market_url" />
        <CButton color="primary" type="submit"> PROCEDI CON L’ORDINE </CButton>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Query } from "@syncfusion/ej2-data";
import { ApiCall, GetDataManagerNew } from "../../../ds";
import {
  MarketCartTableVariantImage,
  MarketCartTableVariantPrice,
  MarketCartTableVariantQuantity,
  MarketCartTableVariantTitle,
  MarketCartTableVariantTotal,
} from ".";
import { addOrReplace, formatPrice } from "../../../helpers/common";

export default {
  name: "MarketCartTable",

  props: {
    market: { type: Object, required: true },
    bus: { type: Object },
  },

  computed: {
    ...mapGetters("connections", ["familyId", "currencySymbol"]),

    lang: function () {
      return this.$i18n.locale;
    },

    items() {
      if (this.cart) {
        return this.cart.items;
      }
      return [];
    },

    checkoutActionUrl() {
      return this.cart ? this.cart.CheckoutUrl : "";
    },

    token() {
      return this.$auth.token();
    },

    redirect_url() {
      const currentUrl = new URL(window.location);
      const ordersRoute = this.$router.resolve({
        name: "UserMarketOrders",
        params: { familyId: this.familyId },
      });
      return `${currentUrl.origin}${ordersRoute.href}`;
    },

    carts_url() {
      const currentUrl = new URL(window.location);
      const cartsRoute = this.$router.resolve({
        name: "UserMarketCarts",
        params: { familyId: this.familyId },
      });
      return `${currentUrl.origin}${cartsRoute.href}`;
    },

    market_url() {
      const currentUrl = new URL(window.location);
      const marketRoute = this.$router.resolve({
        name: "UserMarketDetail",
        params: { familyId: this.familyId, marketId: this.market.market.id },
      });
      return `${currentUrl.origin}${marketRoute.href}`;
    },
  },

  mounted() {
    this.loadCart();
    this.$root.$on("onCartItemRemove", this.onCartItemRemove);
    this.$root.$on("onCartItemChangeQuantity", this.onCartItemChangeQuantity);
    this.$root.$on("onCartItemUpdate", this.onCartItemUpdate);
  },

  beforeDestroy() {
    this.$root.$off("onCartItemRemove", this.onCartItemRemove);
    this.$root.$off("onCartItemChangeQuantity", this.onCartItemChangeQuantity);
    this.$root.$off("onCartItemUpdate", this.onCartItemUpdate);
  },

  data: function () {
    return {
      cart: undefined,

      cartChanges: [],

      cartUpdating: false,

      imageTemplate: () => {
        return { template: MarketCartTableVariantImage };
      },

      nameTemplate: () => {
        return {
          template: {
            extends: MarketCartTableVariantTitle,
            propsData: {
              marketId: this.market.market.id,
            },
          },
        };
      },

      priceTemplate: () => {
        return { template: MarketCartTableVariantPrice };
      },

      quantityTemplate: () => {
        return {
          template: {
            extends: MarketCartTableVariantQuantity,
            propsData: {
              marketId: this.market.market.id,
            },
          },
        };
      },

      totalTemplate: () => {
        return {
          template: {
            extends: MarketCartTableVariantTotal,
          },
        };
      },
    };
  },

  methods: {
    loadCart() {
      const self = this;
      const familyId = this.familyId;
      const marketId = this.market.market.id;

      self.$refs.grid.showSpinner();

      return ApiCall(
        GetDataManagerNew("family_market_current_cart", [familyId, marketId]),
        new Query(),
        (response) => {
          self.$refs.grid.hideSpinner();
          self.cart = response.result;
        },
        (response) => {
          self.$refs.grid.hideSpinner();
          console.log(
            `Error fetching cart (familyId: ${familyId}, marketId: ${marketId})`
          );
          console.error(response);
        }
      );
    },

    cartTotal() {
      return formatPrice(this.cart.view.tot, this.currencySymbol).format();
    },

    updateCart(cart) {
      const self = this;
      const marketId = cart.market;
      this.cartUpdating = true;
      this.$store
        .dispatch("shop/setCartQuantity", {
          marketId,
          items: [...this.cartChanges],
        })
        .then(() => {
          self.cartChanges = [];
          self.cartUpdating = false;
          self.loadCart();
          self.$store.dispatch("toaster/add", {
            title: "OK!",
            text: "Carrello aggiornato con successo",
            color: "success",
            autohide: true,
          });
        });
    },

    onCartItemRemove({ row, data }) {
      const self = this;

      const ppm_id = row.ppm;
      const quantity = 0;
      const marketId = data.marketId;

      if (this.market.market.id === marketId) {
        this.$store
          .dispatch("shop/setCartQuantity", {
            marketId,
            items: [{ ppm_id, quantity }],
          })
          .then(() => {
            self.loadCart();
            self.$store.dispatch("toaster/add", {
              title: "OK!",
              text: "Prodotto rimossso con successo",
              color: "success",
              autohide: true,
            });
          });
      }
    },

    onCartItemUpdate({ row, data }) {
      const self = this;

      const ppm_id = row.ppm;
      const quantity = data.quantity;
      const marketId = data.marketId;

      if (this.market.market.id === marketId) {
        this.$store
          .dispatch("shop/setCartQuantity", {
            marketId,
            items: [{ ppm_id, quantity }],
          })
          .then(() => {
            self.loadCart();
            self.$store.dispatch("toaster/add", {
              title: "OK!",
              text: "Quantità aggiornata con successo",
              color: "success",
              autohide: true,
            });
          });
      }
    },

    onCartItemChangeQuantity({ row, data }) {
      const ppm_id = row.ppm;
      const { marketId, quantity } = data;
      if (this.market.market.id === marketId) {
        this.cartChanges = addOrReplace(
          this.cartChanges,
          {
            ppm_id,
            quantity,
          },
          "ppm_id"
        );
      }
    },
  },
};
</script>
