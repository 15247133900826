<template>
  <CModal :show="show" :closeOnBackdrop="false" :centered="true" size="xl">
    <template #header>
      <h5 class="modal-title">{{ title }}</h5>
    </template>

    <CAlert v-if="error !== null" color="danger" class="mb-3">
      {{ error }}
    </CAlert>

    <form action="" @submit.prevent="onSubmit" ref="form">
      <CSelect
        label="Argomento"
        :options="messageTypeOptions"
        :value.sync="messageType"
        custom
      />

      <div class="form-group">
        <label for="message">Messaggio</label>
        <textarea
          :class="{ 'form-control': true, 'is-invalid': haveFieldError('msg') }"
          id="message"
          v-model="message"
          rows="9"
        ></textarea>
        <div class="invalid-feedback d-block" v-if="haveFieldError('msg')">
          {{ getFieldError("msg") }}
        </div>
      </div>
      <div v-show="messageType === 'PRODUCTS'">
        <CRow class="mb-3">
          <CCol
            ><strong
              >Seleziona eventuali articoli a cui fa riferimento il
              messaggio:</strong
            ></CCol
          >
        </CRow>
        <div class="details-table-wrapper">
          <ejs-grid
            ref="grid"
            :dataSource="details"
            :allowSelection="true"
            :selectionSettings="selectionSettings"
            @headerCellInfo="headerCellInfo"
          >
            <e-columns>
              <e-column type="checkbox" width="40" textAlign="left"></e-column>
              <e-column
                field="id"
                headerText=""
                isPrimaryKey="{true}"
                :template="imageTemplate"
                width="90"
                textAlign="Center"
              ></e-column>
              <e-column :template="nameTemplate" headerText="Nome"></e-column>
              <e-column
                :template="producerTemplate"
                width="240"
                headerText="Produttore"
              ></e-column>
              <e-column
                :template="quantityTemplate"
                headerText="Quantità"
                textAlign="right"
                width="100"
              ></e-column>
              <e-column :template="statusTemplate" width="200"></e-column>
              <e-column
                :template="totalTemplate"
                textAlign="right"
                headerText="Totale"
                width="120"
              ></e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </div>
    </form>

    <template #footer>
      <CButton color="primary" variant="outline" @click.prevent="close"
        >ANNULLA</CButton
      >
      <vue-ladda
        :loading="loading"
        data-style="zoom-in"
        button-class="btn btn-primary px-4"
        @click.prevent="onSubmit"
        >CONFERMA</vue-ladda
      >
    </template>
  </CModal>
</template>

<script>
import get from "lodash/get";
import EventBus from "../../../helpers/EventBus";
import MarketOrderTableVariantImage from "./MarketOrderTableVariantImage";
import MarketOrderTableVariantQuantity from "./MarketOrderTableVariantQuantity";
import MarketOrderTableVariantTitle from "./MarketOrderTableVariantTitle";
import MarketOrderTableVariantProducer from "./MarketOrderTableVariantProducer";
import MarketOrderTableVariantStatus from "./MarketOrderTableVariantStatus";
import MarketOrderTableVariantTotal from "./MarketOrderTableVariantTotal";

export default {
  name: "MarketOrderMessageModal",

  data() {
    return {
      loading: false,
      error: null,
      messageType: "DELIVERY",
      message: "",

      messageTypeOptions: [
        { value: "DELIVERY", label: "Problema con la consegna" },
        { value: "PRODUCTS", label: "Problema con uno o più prodotti" },
      ],

      selectionSettings: {
        persistSelection: false,
        enableToggle: true,
        checkboxOnly: true,
      },

      imageTemplate: () => {
        return {
          template: {
            extends: MarketOrderTableVariantImage,
          },
        };
      },

      nameTemplate: () => {
        return {
          template: {
            extends: MarketOrderTableVariantTitle,
          },
        };
      },

      quantityTemplate: () => {
        return {
          template: {
            extends: MarketOrderTableVariantQuantity,
          },
        };
      },

      statusTemplate: () => {
        return {
          template: {
            extends: MarketOrderTableVariantStatus,
            propsData: {
              openIncidentDetails: false,
            },
          },
        };
      },

      producerTemplate: () => {
        return {
          template: {
            extends: MarketOrderTableVariantProducer,
          },
        };
      },

      totalTemplate: () => {
        return {
          template: {
            extends: MarketOrderTableVariantTotal,
          },
        };
      },
    };
  },

  watch: {
    show() {
      // reset on open/close modal
      this.reset();
    },
  },

  computed: {
    title() {
      return "Invia messaggio";
    },

    show() {
      return this.$store.state.messages.create.show;
    },

    order() {
      return this.$store.state.messages.create.order;
    },

    details() {
      return this.$store.state.messages.create.details;
    },

    lang: function () {
      return this.$i18n.locale;
    },

    user() {
      return this.$auth.user() || null;
    },
  },

  methods: {
    headerCellInfo(args) {
      // remove grid column header checkbox
      const el = args.node.getElementsByClassName("e-checkbox-wrapper")[0];
      el && el.remove();
    },

    reset() {
      this.loading = false;
      this.error = null;
      this.message = "";
    },

    close() {
      this.$store.commit("messages/closeModal");
    },

    onSubmit() {
      this.error = null;

      const detailsId =
        this.messageType === "PRODUCTS"
          ? this.$refs.grid.getSelectedRecords().map((item) => item.id)
          : this.details.map((item) => item.id);

      if (detailsId.length === 0) {
        this.error = "Bisogna selezionare almeno un prodotto";
        return;
      }

      const formData = {
        familyId: this.order.family.id,
        message: this.message,
        messageType: this.messageType,
        details: detailsId,
      };

      this.loading = true;
      this.$store
        .dispatch("messages/createMessage", formData)
        .then(() => {
          EventBus.$emit("messages:created", this.order);
          this.$store.commit("messages/resetState");
        })
        .catch((error) => {
          this.error = error.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    haveFieldError(field) {
      return get(this.error, `errors.fields.${field}`, null) !== null;
    },

    getFieldError(field) {
      return get(this.error, `errors.fields.${field}`, null);
    },
  },
};
</script>

<style scoped>
.details-table-wrapper {
  max-height: 300px;
  overflow: scroll;
}
</style>
