<template>
  <div class="d-flex justify-content-end">
    <dropdown-menu :items="actions" :select="onActionSelect" class="ml-auto">
      <font-awesome-icon icon="ellipsis-h"
    /></dropdown-menu>
  </div>
</template>

<script>
import get from "lodash/get";
import { INVOICE_ACTIONS, INVOICE_ACTIONS_LABEL } from "../../../config/global";

export default {
  name: "MarketInvoicesTableActions",

  data() {
    return {
      INVOICE_ACTIONS_LABEL,
    };
  },

  computed: {
    actions() {
      const actions = [
        {
          id: INVOICE_ACTIONS.DOWNLOAD_PDF,
          text: INVOICE_ACTIONS_LABEL.DOWNLOAD_PDF,
        },
        {
          id: INVOICE_ACTIONS.DOWNLOAD_CSV,
          text: INVOICE_ACTIONS_LABEL.DOWNLOAD_CSV,
        },
      ];
      return actions;
    },
  },

  methods: {
    isPaid() {
      return get(this.data, "payed", false); // fixme
    },

    onActionSelect(args) {
      const { id } = args.item;
      const { file_pdf, file_csv } = this.data;

      if (id === INVOICE_ACTIONS.DOWNLOAD_PDF && file_pdf) {
        window.open(file_pdf, "_blank");
      }

      if (id === INVOICE_ACTIONS.DOWNLOAD_CSV && file_csv) {
        window.open(file_csv, "_blank");
      }
    },
  },
};
</script>
