<template>
  <div class="text-right" :class="{ opacity50: disabled }">
    <div class="ui-type-body font-weight-bold">
      <span class="mr-2" :class="{ strikethrough: disabled }">{{
        presentQuatity()
      }}</span>
    </div>
  </div>
</template>

<script>
import get from "lodash/get";
import { getOptionLabel } from "../../../helpers/common";
import { getPriceUnitOptions, getPwOptions } from "../../../helpers/options";
import { ORDER_STATUS } from "../../../config/global";

export default {
  name: "MarketOrderTableVariantQuantity",

  data() {
    return {
      data: {},
    };
  },
  computed: {
    disabled() {
      return this.data.status === ORDER_STATUS.CANCELED;
    },
  },
  methods: {
    suffix() {
      const pp = get(this.data, "ppm.lot.productproducer");
      const pw_option = get(pp, "extra.pw_option", 1);
      if (pw_option === 3) {
        return `${getOptionLabel(pp.pw_unit, getPriceUnitOptions())}`;
      } else {
        return `${getOptionLabel(pw_option, getPwOptions(), "short")}`;
      }
    },

    presentQuatity() {
      return `${this.data.qta} ${this.suffix()}`;
    },
  },
};
</script>
