<template>
  <ejs-grid
    ref="grid"
    :dataSource="dm"
    :allowSorting="true"
    :allowPaging="true"
    :query="query"
  >
    <e-columns>
      <e-column
        field="id"
        headerText=""
        isPrimaryKey="{true}"
        :template="imageTemplate"
        width="90"
        textAlign="Center"
      ></e-column>
      <e-column
        :template="nameTemplate"
        :field="`ppl.translations.${lang}.name`"
        headerText="Nome"
      ></e-column>
      <e-column
        :template="categoryTemplate"
        :field="`ppl.productproducer.category.translations.${lang}.name`"
        headerText="Categoria"
      ></e-column>
      <e-column
        field="ppl.productproducer.producer.role.name"
        :template="producerTemplate"
        headerText="Produttore"
        width="240"
      ></e-column>
      <e-column
        field="ppm.price"
        :template="priceTemplate"
        headerText="Prezzo"
        width="240"
      ></e-column>
      <e-column
        :template="quantityTemplate"
        headerText="Quantità"
        width="220"
      ></e-column>
    </e-columns>
  </ejs-grid>
</template>

<script>
import { mapGetters } from "vuex";
import { Sort, Page } from "@syncfusion/ej2-vue-grids";
import { Query, Predicate } from "@syncfusion/ej2-data";
import { GetDataManagerNew } from "../../../ds";
import {
  MarketProductTableVariantCategory,
  MarketProductTableVariantImage,
  MarketProductTableVariantPrice,
  MarketProductTableVariantTitle,
  MarketProductTableVariantQuantity,
  MarketProductTableVariantProducer,
} from "./index";

export default {
  name: "MarketShowcaseTable",

  provide: {
    grid: [Sort, Page],
  },

  props: {
    filter: {
      type: Object,
      default: () => {
        return {};
      },
    },
    bus: { type: Object },
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),

    lang: function () {
      return this.$i18n.locale;
    },
  },

  mounted() {
    const self = this;
    this.bus.$on("onSearch", (filter) => {
      self.loadProducts(filter);
    });

    this.$root.$on("onCartAdd", this.onCartAdd);
  },

  beforeDestroy() {
    this.bus.$off("onSearch");
    this.$root.$off("onCartAdd", this.onCartAdd);
  },

  data: function () {
    const familyId = this.$store.state.connections.current.family.id;
    const marketId = this.$store.state.shop.market.market.id;
    const dm = GetDataManagerNew("family_market_variants", [
      familyId,
      marketId,
    ]);
    return {
      dm: dm,
      query: new Query(),

      imageTemplate: () => {
        return {
          template: {
            extends: MarketProductTableVariantImage,
          },
        };
      },
      nameTemplate: () => {
        return { template: MarketProductTableVariantTitle };
      },
      categoryTemplate: () => {
        return { template: MarketProductTableVariantCategory };
      },
      producerTemplate: () => {
        return { template: MarketProductTableVariantProducer };
      },
      priceTemplate: () => {
        return { template: MarketProductTableVariantPrice };
      },
      quantityTemplate: () => {
        return { template: MarketProductTableVariantQuantity };
      },
    };
  },
  methods: {
    loadProducts(filter) {
      let query = new Query();
      let predicate = new Predicate("id", "greaterthanorequal", 0);

      const { search, categoryId, producerId, certificationsIds } = filter;
      const lang = this.$i18n.locale;

      if (search !== "") {
        query = query.search(search, [
          `ppl.translations.${lang}.name`,
          `ppl.productproducer.translations.${lang}.name`,
          `ppl.productproducer.producer.role.name`,
        ]);
      }

      if (
        categoryId &&
        categoryId !== null &&
        !isNaN(parseInt(categoryId, 10))
      ) {
        predicate = predicate.and(
          "ppl.productproducer.category_id",
          "equal",
          categoryId
        );
      }
      if (
        producerId &&
        producerId !== null &&
        !isNaN(parseInt(producerId, 10))
      ) {
        predicate = predicate.and(
          "ppl.productproducer.producer.id",
          "equal",
          producerId
        );
      }

      if (Array.isArray(certificationsIds) && certificationsIds.length) {
        let certificationsPredicate = null;
        certificationsIds.forEach((el) => {
          if (certificationsPredicate === null) {
            certificationsPredicate = new Predicate(
              "pp__pp__certifications__id",
              "equal",
              el.value
            );
          } else {
            certificationsPredicate = certificationsPredicate.or(
              "pp__pp__certifications__id",
              "equal",
              el.value
            );
          }
        });
        predicate = predicate.and(certificationsPredicate);
      }

      query = query.where(predicate);
      query.page(1, 12);

      this.query = query;
    },

    onCartAdd({ row, data }) {
      const self = this;

      const ppm_id = row.ppm.id;
      const quantity = data.quantity;

      this.$store
        .dispatch("shop/setCartQuantity", { items: [{ ppm_id, quantity }] })
        .then((response) => {
          self.$store.dispatch("toaster/add", {
            title: "OK!",
            text: "Quantità aggiornata con successo",
            color: "success",
            autohide: true,
          });
        });
    },
  },
};
</script>
