<template>
  <CCard>
    <CCardBody>
      <CRow class="justify-content-between align-items-center mb-3">
        <CCol>
          <div class="d-flex align-items-center">
            <figure
              v-if="marketHaveLogo(market)"
              class="m-0 mr-3"
              @click.prevent="openMarket(market)"
            >
              <img
                :src="marketLogoThumb(market)"
                alt=""
                class="border rounded-circle"
                width="64"
                height="64"
              />
            </figure>
            <div>
              <div class="d-flex align-items-center">
                <span class="d-inline-block ui-type-display-lg mr-3">
                  <a href="" @click.prevent="openMarket(market)">{{
                    marketName(market)
                  }}</a>
                </span>
                <span
                  class="badge badge-success mr-2"
                  v-if="showDelivery(market)"
                >
                  <font-awesome-icon icon="truck" /> Servizio consegna
                  attivo</span
                >
                <span class="badge badge-light" v-if="showPickup(market)">
                  <font-awesome-icon icon="store" /> Ritira nei nostri punti
                  ritiro</span
                >
              </div>
              <div v-html="market.market.role.desc_short"></div>
            </div>
          </div>
        </CCol>
        <CCol col="auto" class="ml-auto text-right" v-if="!showProducts">
          <div class="ui-type-caption text-uppercase">TOTALE SPESA</div>
          <div class="ui-type-display-sm text-uppercase">
            {{ cartTotal() }}
          </div>
          <CButton
            color="primary"
            variant="outline"
            @click="showProducts = true"
            >VISUALIZZA CARRELLO</CButton
          >
        </CCol>
      </CRow>

      <div>
        <MarketCartTable v-if="showProducts" :market="market" />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from "vuex";
import { formatPrice } from "../../../helpers/common";
import marketMixin from "../../../mixins/market";
import MarketCartTable from "./MarketCartTable";

export default {
  name: "MarketCart",

  mixins: [marketMixin],

  components: {
    MarketCartTable,
  },

  props: {
    market: { type: Object, required: true },
    loadProducts: { type: Boolean, default: false },
  },

  data() {
    return {
      showProducts: false,
    };
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol", "familyId"]),
  },

  mounted() {
    if (this.loadProducts) {
      this.showProducts = true;
    }
  },

  methods: {
    cartTotal() {
      if (this.market.cart) {
        return formatPrice(
          this.market.cart.view.tot,
          this.currencySymbol
        ).format();
      }
      return "";
    },

    openMarket(market) {
      this.$store.commit("shop/setMarket", market);
      this.$store.commit("shop/setCart", {});
      this.$router.push({
        name: "UserMarketDetail",
        params: { familyId: this.familyId, marketId: market.market.id },
      });
    },
  },
};
</script>
