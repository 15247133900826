<template>
  <div>
    <div
      v-if="status.label !== ''"
      :class="getBadgeClasses()"
      @click.prevent="onClick"
    >
      {{ status.label }}
    </div>
    <div v-if="status.detail">{{ status.detail }}</div>
  </div>
</template>

<script>
import get from "lodash/get";
import { ORDER_STATUS, INCIDENT_ACTIONS } from "../../../config/global";
import { getOptionLabel } from "../../../helpers/common";
import { getPriceUnitOptions, getPwOptions } from "../../../helpers/options";

export default {
  name: "MarketOrderTableVariantStatus",

  props: {
    openIncidentDetails: { type: Boolean, default: true },
  },

  computed: {
    status() {
      return this.orderDetailStatus(this.data);
    },
  },

  methods: {
    pwUnit() {
      const pp = get(this.data, "ppm.lot.productproducer");
      const pw_option = get(pp, "extra.pw_option", 1);
      if (pw_option === 3) {
        return `${getOptionLabel(pp.pw_unit, getPriceUnitOptions())}`;
      } else {
        return `${getOptionLabel(pw_option, getPwOptions(), "short")}`;
      }
    },

    orderDetailStatus(orderDetail) {
      const { status, incident } = orderDetail;
      const {
        CREATE,
        PAYMENT_WAIT,
        APPROVAL,
        CANCELED,
        ALERT,
        PRODUCER_IN_CHARGE,
        MARKET_ARRIVED,
        MARKET_IN_CHARGE,
        ISLAND_ARRIVED,
        ISLAND_IN_CHARGE,
        FAMILY_IN_CHARGE,
        DELIVERED,
      } = ORDER_STATUS;

      // incident
      if (incident !== null) {
        if (
          get(incident, "detail.extra.action", "") === "" ||
          get(incident, "close", false) === false
        ) {
          return { label: "segnalazione in gestione", color: "warning" };
        }

        if (
          get(incident, "detail.extra.action", "") === INCIDENT_ACTIONS.DISCOUNT
        ) {
          return { label: "sconto", color: "blue", detail: "" };
        }

        if (
          get(incident, "detail.extra.action", "") ===
          INCIDENT_ACTIONS.REPLACEMENT
        ) {
          return {
            label: "sostituito",
            color: "blue",
            detail: get(incident, "detail.extra.replacedWith", ""),
          };
        }

        if (
          get(incident, "detail.extra.action", "") ===
          INCIDENT_ACTIONS.CHANGE_WEIGHT
        ) {
          return {
            label: "cambio peso",
            color: "warning",
            detail: `${get(
              incident,
              "detail.extra.newWeight",
              ""
            )} ${this.pwUnit()}`,
          };
        }

        if (
          get(incident, "detail.extra.action", "") ===
          INCIDENT_ACTIONS.CANCELLED
        ) {
          return { label: "annullato", color: "danger", detail: "" };
        }

        if (
          get(incident, "detail.extra.action", "") ===
          INCIDENT_ACTIONS.CANCELLED_FOR
        ) {
          return { label: "annullato per...", color: "warning", detail: "" };
        }
      }

      // status

      if ([CREATE, PAYMENT_WAIT, APPROVAL].includes(status)) {
        return { label: "in approvazione", color: "info", detail: "" };
      }

      if ([CANCELED].includes(status)) {
        return { label: "annullato", color: "danger", detail: "" };
      }

      if ([ALERT].includes(status)) {
        return { label: "segnalato", color: "warning", detail: "" };
      }

      if (
        [PRODUCER_IN_CHARGE, MARKET_ARRIVED, MARKET_IN_CHARGE].includes(status)
      ) {
        return { label: "in lavorazione", color: "success", detail: "" };
      }

      if ([ISLAND_ARRIVED].includes(status)) {
        return { label: "in transito", color: "blue" };
      }

      if ([ISLAND_IN_CHARGE].includes(status)) {
        return {
          label: "in consegna/pronto per il ritiro",
          color: "blue",
          detail: "",
        };
      }

      if ([FAMILY_IN_CHARGE].includes(status)) {
        return { label: "consegnato/ritirato", color: "success", detail: "" };
      }

      if ([DELIVERED].includes(status)) {
        return { label: "consegnato", color: "success", detail: "" };
      }

      return { label: "", color: "", detail: "" };
    },

    getBadgeClasses() {
      const { status } = this;
      const classes = [`badge badge-${status.color}`];
      if (this.openIncidentDetails && this.haveIncident()) {
        classes.push("cursor-pointer");
      }
      return classes.join(" ");
    },

    haveIncident() {
      return get(this.data, "incident", null) !== null;
    },

    onClick() {
      if (this.openIncidentDetails && this.haveIncident()) {
        this.$store.commit("incidents/viewSetIncidentDetail", this.data);
        this.$store.commit("incidents/viewShow");
      }
    },
  },
};
</script>
