<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol col="auto" md="3">
          <div class="ui-type-caption text-uppercase">Ordine</div>
          <div class="ui-type-display-sm">#{{ order.id }}</div>
          <MarketOrderStatusBadge :order="order" />
        </CCol>
        <CCol col="auto" :lg="{ order: '2' }" class="ml-auto">
          <div class="d-flex flex-column">
            <vue-ladda
              :loading="loading.details"
              data-style="zoom-in"
              data-spinner-color="#000"
              button-class="btn btn-primary text-uppercase d-block mb-2"
              @click.prevent="onViewDatails()"
              >Dettagli ordine</vue-ladda
            >
            <vue-ladda
              v-if="messageButton"
              :loading="loading.messages"
              data-style="zoom-in"
              data-spinner-color="#000"
              button-class="btn btn-outline-primary text-uppercase d-block"
              @click.prevent="onMessage()"
              >MESSAGGI E ASSISTENZA</vue-ladda
            >
          </div>
        </CCol>
        <CCol col="12" lg>
          <CRow>
            <CCol>
              <div class="mb-4">
                <div class="ui-type-caption text-uppercase">Data ordine</div>
                <div class="ui-type-body font-weight-bold">
                  {{ orderDate(order) }}
                </div>
              </div>
              <div class="mb-4">
                <div class="ui-type-caption text-uppercase">
                  Data consegna prevista
                </div>
                <div class="ui-type-body font-weight-bold">
                  {{ orderScheduledDelivery(order) }}
                </div>
              </div>
            </CCol>
            <CCol>
              <div class="mb-4">
                <div class="ui-type-caption text-uppercase">Totale spesa</div>
                <div class="ui-type-body font-weight-bold">
                  {{ orderTotal(order, currencySymbol) }}
                  <del v-if="orderTotalIsChanged(order)">{{
                    orderTotalOrig(order, currencySymbol)
                  }}</del>
                </div>
              </div>
              <div class="mb-4">
                <div class="ui-type-caption text-uppercase">
                  {{ orderDeliveryLabel(order) }}
                </div>
                <div class="ui-type-body font-weight-bold">
                  {{ orderDeliveryName(order) }}
                </div>
              </div>
            </CCol>
            <CCol>
              <div class="mb-4">
                <div class="ui-type-caption text-uppercase">Mercato</div>
                <div class="ui-type-body font-weight-bold">
                  {{ orderMarketName(order) }}
                </div>
              </div>
              <div class="mb-4" v-if="showFamilyName">
                <div class="ui-type-caption text-uppercase">Aquirente</div>
                <div class="ui-type-body font-weight-bold">
                  {{ orderFamilyName(order) }}
                </div>
              </div>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <ejs-grid ref="grid" :dataSource="rows" v-show="showDetails">
        <e-columns>
          <e-column
            field="id"
            headerText=""
            isPrimaryKey="{true}"
            :template="imageTemplate"
            width="90"
            textAlign="Center"
          ></e-column>
          <e-column :template="nameTemplate" headerText="Nome"></e-column>
          <e-column
            :template="producerTemplate"
            width="240"
            headerText="Produttore"
          ></e-column>
          <e-column
            :template="quantityTemplate"
            headerText="Quantità"
            textAlign="right"
            width="100"
          ></e-column>
          <e-column :template="statusTemplate" width="150"></e-column>
          <e-column
            :template="totalTemplate"
            textAlign="right"
            headerText="Totale"
            width="120"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </CCardBody>
  </CCard>
</template>

<script>
import { GetDataManagerNew, ApiCall } from "../../../ds";
import { Query } from "@syncfusion/ej2-data";
import { mapGetters } from "vuex";
import orderMixin from "../../../mixins/order";
import MarketOrderStatusBadge from "./MarketOrderStatusBadge";
import MarketOrderTableVariantImage from "./MarketOrderTableVariantImage";
import MarketOrderTableVariantProducer from "./MarketOrderTableVariantProducer";
import MarketOrderTableVariantQuantity from "./MarketOrderTableVariantQuantity";
import MarketOrderTableVariantStatus from "./MarketOrderTableVariantStatus";
import MarketOrderTableVariantTitle from "./MarketOrderTableVariantTitle";
import MarketOrderTableVariantTotal from "./MarketOrderTableVariantTotal";
import EventBus from "../../../helpers/EventBus";

export default {
  name: "MarketOrder",

  mixins: [orderMixin],

  components: {
    MarketOrderStatusBadge,
  },

  props: {
    order: { type: Object, required: true },
    type: { type: String, default: "family" },
    viewDetails: { type: Boolean, default: false },
    messageButton: { type: Boolean, default: true },
    showFamilyName: { type: Boolean, default: false },
  },

  data() {
    return {
      showDetails: false,

      loading: {
        details: false,
        messages: false,
      },

      rows: [],

      imageTemplate: () => {
        return {
          template: {
            extends: MarketOrderTableVariantImage,
          },
        };
      },

      nameTemplate: () => {
        return {
          template: {
            extends: MarketOrderTableVariantTitle,
          },
        };
      },

      quantityTemplate: () => {
        return {
          template: {
            extends: MarketOrderTableVariantQuantity,
          },
        };
      },

      statusTemplate: () => {
        return {
          template: {
            extends: MarketOrderTableVariantStatus,
          },
        };
      },

      producerTemplate: () => {
        return {
          template: {
            extends: MarketOrderTableVariantProducer,
          },
        };
      },

      totalTemplate: () => {
        return {
          template: {
            extends: MarketOrderTableVariantTotal,
          },
        };
      },
    };
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol", "familyId"]),
  },

  mounted() {
    if (this.viewDetails) {
      this.onViewDatails();
    }
    EventBus.$on("messages:created", this.reloadDetails);
  },

  beforeDestroy() {
    EventBus.$off("messages:created", this.reloadDetails);
  },

  methods: {
    onViewDatails() {
      if (this.showDetails === false) {
        if (this.rows.length === 0) {
          this.loading.details = true;
          this.getOrderDetails().then(() => {
            this.loading.details = false;
            this.showDetails = true;
          });
        } else {
          this.showDetails = true;
        }
      } else {
        this.showDetails = false;
      }
    },

    reloadDetails(order) {
      if (order.id === this.order.id) {
        this.loading.details = true;
        this.getOrderDetails().then(() => {
          this.loading.details = false;
        });
      }
    },

    onMessage() {
      this.loading.messages = true;
      if (this.rows.length === 0) {
        this.getOrderDetails().then(() => {
          this.openMessageModal();
        });
      } else {
        this.openMessageModal();
      }
    },

    getDataManagerInstance() {
      if (this.type === "family") {
        return GetDataManagerNew("role_order_family_details", [
          this.familyId,
          this.order.id,
        ]);
      }
      if (this.type === "role") {
        const roleId = this.$store.state.role.id;
        return GetDataManagerNew("role_order_role_details", [
          roleId,
          this.order.id,
        ]);
      }
      return null;
    },

    getOrderDetails() {
      const self = this;
      const query = new Query();
      const dm = this.getDataManagerInstance();
      if (!dm) return;
      return ApiCall(
        dm,
        query,
        (response) => {
          self.rows = response.result;
          return response;
        },
        (e) => {
          console.error(e);
          self.loading.details = false;
        }
      );
    },

    openMessageModal() {
      this.loading.messages = false;
      const paylod = {
        order: this.order,
        details: this.rows,
        show: true,
      };
      this.$store.commit("messages/openModal", paylod);
    },
  },
};
</script>
