<template>
  <vue-ladda
    :loading="cartUpdating"
    data-style="zoom-in"
    button-class="btn btn-primary"
    @click.prevent="goToCartDetail()"
  >
    <font-awesome-icon icon="shopping-cart" />
    {{ cart && cart.items ? cart.items.length : 0 }}
    PRODOTTI
  </vue-ladda>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "MarketCartButton",
  computed: {
    ...mapGetters("connections", ["familyId"]),
    ...mapState("shop", ["cart", "cartUpdating"]),
  },
  methods: {
    goToCartDetail() {
      const familyId = this.familyId;
      const marketId = this.cart.market;
      this.$router.push({
        name: "UserMarketCartDetail",
        params: { familyId, marketId },
      });
    },
  },
};
</script>
